import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/packages/paste-website/src/layouts/DefaultLayout.tsx";
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Anchor } from '@twilio-paste/anchor';
import { Box } from '@twilio-paste/box';
import { Paragraph } from '@twilio-paste/paragraph';
import { DoDont, Do, Dont } from '../../../components/DoDont';
import { SidebarCategoryRoutes } from '../../../constants';
import Changelog from '@twilio-paste/anchor/CHANGELOG.md';
export const pageQuery = graphql`
  {
    allPasteComponent(filter: {name: {eq: "@twilio-paste/anchor"}}) {
      edges {
        node {
          name
          description
          status
          version
        }
      }
    }
    file(sourceInstanceName: {eq: "assets"}, relativePath: {eq: "images/anchor-dont-1@4x.png"}) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    mdx(fields: {slug: {eq: "/components/anchor/"}}) {
      fileAbsolutePath
      frontmatter {
        slug
        title
      }
      headings {
        depth
        value
      }
    }
  }
`;
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ComponentHeader = makeShortcode("ComponentHeader");
const PageAside = makeShortcode("PageAside");
const LivePreview = makeShortcode("LivePreview");
const ChangelogRevealer = makeShortcode("ChangelogRevealer");
const layoutProps = {
  pageQuery,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <ComponentHeader name={props.pageContext.frontmatter.title} categoryRoute={SidebarCategoryRoutes.COMPONENTS} githubUrl="https://github.com/twilio-labs/paste/tree/main/packages/paste-core/components/anchor" storybookUrl="/?path=/story/components-anchor--default" abstractUrl="https://share.goabstract.com/b202a1d0-0129-4a6e-bc2c-701ae5250955" data={props.data.allPasteComponent.edges} mdxType="ComponentHeader" />
    <hr></hr>
    <contentwrapper>
      <PageAside data={props.data.mdx} mdxType="PageAside" />
      <content>
        <h2>{`Guidelines`}</h2>
        <h3>{`About anchors`}</h3>
        <Paragraph mdxType="Paragraph">{props.pageContext.frontmatter.description}</Paragraph>
        <p>{`Example use cases may include:`}</p>
        <ul>
          <li parentName="ul">{`Linking to another page within an app or website`}</li>
          <li parentName="ul">{`Linking to an external domain outside of the current app or website`}</li>
        </ul>
        <h4>{`Anchor vs. Button`}</h4>
        <p>{`Anchors should be used in place of a button if you only need to create a hyperlink to some other page or content. Anchors should not be used for submitting a form, closing a modal, moving to the next step in a flow, or any other click action that a button should handle. Buttons perform an action, like submitting a form; Anchors take you somewhere, like to the documentation page.`}</p>
        <p>{`If you need a click handler, you can use our `}<a parentName="p" {...{
            "href": "/components/button"
          }}>{`Button component`}</a>{`.`}</p>
        <h4>{`Accessibility`}</h4>
        <p>{`The anchor is built with standard accessible practices in mind. Those include an href attribute, link context, and opening and closing tags.`}</p>
        <p>{`The title attribute was not included because it’s not exposed to all browsers in an accessible way, meaning most screen readers and touch-only devices will likely never see that information.`}</p>
        <h2>{`Examples`}</h2>
        <h3>{`Default Anchor`}</h3>
        <p>{`The default anchor is a basic text hyperlink. There are no other anchor variants at this time.`}</p>
        <LivePreview scope={{
          Anchor
        }} language="jsx" mdxType="LivePreview">
  {`<Anchor href="/components">
  Go to Paste components
</Anchor>`}
        </LivePreview>
        <h3>{`External Anchor`}</h3>
        <p>{`If an external URL is used for the href, the target and rel will automatically be updated to:`}</p>
        <p><inlineCode parentName="p">{`target=”_blank”`}</inlineCode>{`
`}<inlineCode parentName="p">{`rel=”noreferrer noopener”`}</inlineCode></p>
        <p>{`This is done for security purposes. Even though the target and rel are set by default for external anchors, they can be overridden using the target and rel props.`}</p>
        <p>{`You can show the link external icon by setting the `}<inlineCode parentName="p">{`showExternal`}</inlineCode>{` prop.`}</p>
        <LivePreview scope={{
          Anchor
        }} language="jsx" mdxType="LivePreview">
  {`<Anchor href="https://www.nlg-npap.org/donate/" showExternal>
  Donate to the National Police Accountability Project
</Anchor>`}
        </LivePreview>
        <h2>{`When to Use an Anchor`}</h2>
        <p>{`You can use an anchor to navigate the user to another webpage.`}</p>
        <DoDont mdxType="DoDont">
  <Do title="Do" body="Anchors should only be used to link to another page, app, or another website." center mdxType="Do">
    <Anchor href="https://paste.twilio.design" mdxType="Anchor">Go to API documentation</Anchor>
  </Do>
  <Dont title="Don't" body="Don’t use an anchor where a button makes more sense, i.e., closing a modal." mdxType="Dont">
    <Img fluid={props.data.file.childImageSharp.fluid} mdxType="Img" />
  </Dont>
        </DoDont>
        <h2>{`Using Anchor with a router`}</h2>
        <p>{`There are many different React based routers. For example there is:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://reactrouter.com/web/guides/quick-start"
            }}>{`React Router`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://reach.tech/router/"
            }}>{`Reach Router`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://nextjs.org/docs/api-reference/next/link"
            }}>{`NextJs Link`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-link/"
            }}>{`Gatsby Link`}</a>{`.`}</li>
        </ul>
        <p>{`They unfortunately all work slightly differently, have different APIs, and many versions that an application might have installed. We want Paste to be used in as many different applications as possible and as such, we have chosen to not support any one router.`}</p>
        <p>{`Anchor is just a styled HTML anchor element. You should be able to use it with any React based router. It just means that you need to do a little work to wire it up inside your application.`}</p>
        <p>{`There are many different ways you can achieve this based on the router you are using, but as an example we include a Codesandbox below using `}<a parentName="p" {...{
            "href": "https://reactrouter.com/web/guides/quick-start"
          }}>{`React Router`}</a>{`, to give you a rough guide on how you might approach it.`}</p>
        <iframe src="https://codesandbox.io/embed/using-anchor-with-react-router-butmi?fontsize=14&hidenavigation=1&theme=dark" style={{
          "width": "100%",
          "height": "500px",
          "border": "0",
          "borderRadius": "4px",
          "overflow": "hidden"
        }} title="Using Anchor with React Router" allow="accelerometer; ambient-light-sensor; camera; encrypted-media; geolocation; gyroscope; hid; microphone; midi; payment; usb; vr; xr-spatial-tracking" sandbox="allow-forms allow-modals allow-popups allow-presentation allow-same-origin allow-scripts"></iframe>
        <hr></hr>
        <h2>{`Usage Guide`}</h2>
        <h3>{`API`}</h3>
        <h4>{`Installation`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-bash"
          }}>{`yarn add @twilio-paste/anchor - or - yarn add @twilio-paste/core
`}</code></pre>
        <h4>{`Usage`}</h4>
        <pre><code parentName="pre" {...{
            "className": "language-js"
          }}>{`import {Anchor} from '@twilio-paste/anchor';

const Component = () => <Anchor href="https://paste.twilio.design">Go to Paste</Anchor>;
`}</code></pre>
        <h4>{`Props`}</h4>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Prop`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Type`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Default`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`href?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`A URL to route to. Required.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`rel?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`string`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Sets the anchor rel attribute. If external href, defaults to 'noreferrer noopener'. Can be overwritten.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`showExternal?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`boolean`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Shows the link external icon.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`false`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`tabindex?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AnchorTabIndexes`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'0', '-1'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`target?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AnchorTargets`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'`}{`_`}{`self', '`}{`_`}{`blank', '`}{`_`}{`parent', '`}{`_`}{`top'. If external href, defaults to '`}{`_`}{`blank'. Can be overwritten.`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`variant?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`AnchorVariants`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'default', 'inverse'`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`'default'`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onClick?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.MouseEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onFocus?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.FocusEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`onBlur?`}</td>
              <td parentName="tr" {...{
                "align": null
              }}><inlineCode parentName="td">{`(event: React.FocusEvent<HTMLElement>)`}</inlineCode></td>
              <td parentName="tr" {...{
                "align": null
              }}></td>
              <td parentName="tr" {...{
                "align": null
              }}>{`null`}</td>
            </tr>
          </tbody>
        </table>
        <ChangelogRevealer mdxType="ChangelogRevealer">
  <Changelog mdxType="Changelog" />
        </ChangelogRevealer>
      </content>
    </contentwrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      